<template>
  <ul>
    <li>
      <router-link
        to="/format"
        :class="{ active: $store.state.type === 'business' }"
        @click.native="$store.commit('setType', 'business')"
        >{{ $t("format.business") }}</router-link
      >
    </li>
    <li>
      <router-link
        to="/private/styling"
        :class="{ active: $store.state.type === 'private' }"
        @click.native="$store.commit('setType', 'private')"
        >{{ $t("format.private") }}</router-link
      >
    </li>
  </ul>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables'
ul
  padding: 0
  margin: 0
  list-style: none
  display: flex
  margin-bottom: 20px
  flex-wrap: wrap
  li
    margin-right: 8px
    margin-bottom: 15px
    a
      word-break: keep-all
      white-space: nowrap
      word-wrap: none
      font-size: 10pt
      font-weight: 600
      text-transform: uppercase
      padding: 5px 12px
      border-radius: 5px
      color: $primary-blue
      border: 1px solid $primary-blue
      text-decoration: none
      background-color: transparent
      &.active
        color: $white
        background-color: $primary-blue
</style>
